import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { errorToString } from "@cg/core/utils";
import { NavigationStructure } from "../interfaces/navigation-structure.interface";
import { NavigationService } from "../services/navigation.service";
import * as NavigationActions from "./navigation.actions";

@Injectable()
export class NavigationEffects {
  public getHeaderNavigationStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.getHeaderNavigationStructure),
      switchMap(() =>
        this.navigationService.getHeaderNavigationStructure().pipe(
          map((res: NavigationStructure) => NavigationActions.getHeaderNavigationStructureSuccess({ payload: res })),
          catchError((error: HttpErrorResponse) =>
            of(NavigationActions.getHeaderNavigationStructureFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public getFooterNavigationStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.getFooterNavigationStructure),
      switchMap(() =>
        this.navigationService.getFooterNavigationStructure().pipe(
          map((res: NavigationStructure) => NavigationActions.getFooterNavigationStructureSuccess({ payload: res })),
          catchError((error: HttpErrorResponse) =>
            of(NavigationActions.getFooterNavigationStructureFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private navigationService: NavigationService
  ) {}
}
