import { type Action, createReducer, on } from "@ngrx/store";
import type { ServiceCenter, ServiceCenterLocation } from "@cg/core/interfaces";
import * as LocationsActions from "./locations.actions";

export const LOCATIONS_FEATURE_KEY = "locations";

export interface LocationsState {
  serviceCenterLocations: ServiceCenterLocation[];
  currentPage: number;
  selectedServiceCenter: ServiceCenter;
  userPosition: google.maps.LatLngLiteral;
  loaded: boolean; // has the ServiceCenter list been loaded
  error?: string | null; // last none error (if any)
  clickedMarker: string;
  toggledServiceCenter: string; // kst des Service Center
  showServiceCenterDetails: boolean; // show location-details
  search: string;
  isServiceCenterClosed: { costCenter: string; isScClosed: boolean };
}

export interface LocationsPartialState {
  readonly [LOCATIONS_FEATURE_KEY]: LocationsState;
}

export const initialState: LocationsState = {
  // set initial required properties
  serviceCenterLocations: null,
  currentPage: 0,
  userPosition: null,
  selectedServiceCenter: null,
  loaded: false,
  error: null,
  clickedMarker: null,
  toggledServiceCenter: null,
  showServiceCenterDetails: false,
  search: null,
  isServiceCenterClosed: null
};

const locationsReducer = createReducer(
  initialState,
  on(LocationsActions.setSearch, (state: LocationsState, { payload }: { payload: string }) => ({
    ...state,
    search: payload,
    serviceCenterLocations: []
  })),
  on(LocationsActions.setCurrentPage, (state: LocationsState, { payload }: { payload: number }) => ({
    ...state,
    currentPage: payload
  })),
  on(
    LocationsActions.setUserPosition,
    (state: LocationsState, { payload }: { payload: { lat: number; lng: number } }) => ({
      ...state,
      userPosition: payload
    })
  ),
  on(
    LocationsActions.setSelectedServiceCenterOnMapClick,
    (state: LocationsState, { payload }: { payload: ServiceCenter }) => ({
      ...state,
      selectedServiceCenter: payload
    })
  ),
  on(LocationsActions.getServiceCenterLocations, (state: LocationsState) => ({
    ...state,
    currentPage: 0,
    loaded: false
  })),
  on(
    LocationsActions.getServiceCenterLocationsSuccess,
    (state: LocationsState, { payload }: { payload: ServiceCenterLocation[] }) => ({
      ...state,
      serviceCenterLocations: payload,
      selectedServiceCenter: null,
      loaded: true,
      error: null
    })
  ),
  on(LocationsActions.getServiceCenterLocationsFailure, (state: LocationsState, { error }: { error: string }) => ({
    ...state,
    loaded: false,
    error,
    selectedServiceCenter: null,
    serviceCenterLocations: null
  })),
  on(
    LocationsActions.getServiceCenterDetailsSuccess,
    (state: LocationsState, { payload }: { payload: ServiceCenter[] }) => ({
      ...state,
      selectedServiceCenter: payload[0],
      loaded: true
    })
  ),
  on(LocationsActions.getServiceCenterDetailsFailure, (state: LocationsState, { error }: { error: string }) => ({
    ...state,
    error,
    loaded: false
  })),
  on(LocationsActions.clearServiceCenterDetails, (state: LocationsState) => ({
    ...state,
    selectedServiceCenter: null
  })),
  on(LocationsActions.setMarkerClicked, (state: LocationsState, { payload }: { payload: string }) => ({
    ...state,
    clickedMarker: payload
  })),
  on(LocationsActions.toggleServiceCenterLocation, (state: LocationsState, { payload }: { payload: string }) => ({
    ...state,
    toggledServiceCenter: state.toggledServiceCenter === payload ? null : payload
  })),
  on(LocationsActions.showServiceCenterDetails, (state: LocationsState) => ({
    ...state,
    showServiceCenterDetails: true
  })),
  on(LocationsActions.closeServiceCenterDetails, (state: LocationsState) => ({
    ...state,
    showServiceCenterDetails: false
  })),
  on(
    LocationsActions.isServiceCenterClosedSuccess,
    (state: LocationsState, { payload }: { payload: { isScClosed: boolean; costCenter: string } }) => ({
      ...state,
      isServiceCenterClosed: {
        costCenter: payload.costCenter,
        isScClosed: payload.isScClosed
      }
    })
  )
);

export function reducer(state: LocationsState | undefined, action: Action) {
  return locationsReducer(state, action);
}
