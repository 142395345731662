import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { lastValueFrom } from "rxjs";
import { filter, first } from "rxjs/operators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { SharedFacade } from "@cg/carglass-shared-state";
import { Cta, Icon, List, Paragraph, Table } from "@cg/content-api/typescript-interfaces";
import { Characteristics, OpeningHours, ServiceCenter, ServiceCenterAddress } from "@cg/core/interfaces";
import { HeadlineComponent, HeadlineType, IconComponent, RichtextComponent } from "@cg/core/ui";
import { arrowsIcon, closeIcon, locationPinIcon } from "@cg/icon";
import {
  BreakpointService,
  CccPhoneInfoComponent,
  CtaComponent,
  Ctaicon,
  CtaiconComponent,
  CtaVariation,
  Definitionlist,
  DefinitionlistComponent,
  ListComponent,
  TableComponent
} from "@cg/shared";
import { LocationsFacade } from "../../+state/locations.facade";

const APPOINTMENT = "location.headline.appointment";
const ROUTE = "location.headline.route";
const SERVICE_ORDER = [
  "Scheibenreparatur",
  "Scheibenaustausch",
  "Scheibenwischeraustausch",
  "Scheibenversiegelung",
  "LKW-geeignet",
  "Notverglasung",
  "Smart Repair"
];

@Component({
  selector: "cg-location-details",
  templateUrl: "./location-details.component.html",
  styleUrls: ["./location-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    IconComponent,
    HeadlineComponent,
    CtaComponent,
    CtaiconComponent,
    TableComponent,
    ListComponent,
    RichtextComponent,
    CccPhoneInfoComponent,
    DefinitionlistComponent
  ]
})
export class LocationDetailsComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public readonly headlineTypes = HeadlineType;
  public readonly services: Definitionlist = [];

  public readonly appointmentCta: Cta = {
    id: "appointment-cta",
    title: APPOINTMENT,
    icon: arrowsIcon,
    link: {
      href: null,
      title: APPOINTMENT,
      text: APPOINTMENT,
      routerLink: false
    },
    arrowDirection: "right",
    variation: CtaVariation.PRIMARY,
    ngTemplate: "cgCta"
  };

  public readonly routeCta: Cta = {
    id: "route-cta",
    title: ROUTE,
    icon: arrowsIcon,
    link: {
      href: "",
      title: ROUTE,
      text: ROUTE,
      routerLink: false,
      target: "_tab"
    },
    arrowDirection: "right",
    variation: CtaVariation.SECONDARY,
    ngTemplate: "cgCta"
  };

  public readonly closeLongCta: Cta = {
    id: "close-long-cta",
    title: "Schließen",
    icon: closeIcon,
    link: {
      href: null,
      title: "Schließen",
      text: "Schließen",
      routerLink: true
    },
    arrowDirection: "left",
    variation: CtaVariation.SECONDARY,
    ngTemplate: "cgCta"
  };

  public readonly closeCta: Ctaicon = {
    id: "close-ctaicon",
    icon: closeIcon,
    link: {
      href: null,
      title: "location.closed",
      text: "location.closed",
      routerLink: true
    },
    ngTemplate: "cgCtaicon"
  };

  public readonly locationIcon: Icon = locationPinIcon;

  public readonly openingHours: Table = {
    headline: "",
    footer: "",
    columns: [],
    rows: [],
    mobilePattern: false,
    ngTemplate: ""
  };

  public readonly serviceList: List = {
    type: "check",
    items: [],
    ngTemplate: "cgList"
  };

  public notes: Paragraph = {
    text: "",
    ngTemplate: "cgParagraph"
  };

  public content: ServiceCenter;
  public isScClosed: boolean;
  public isSmallMobile: boolean;

  public constructor(
    private readonly breakpointService: BreakpointService,
    private locationsFacade: LocationsFacade,
    private sharedFacade: SharedFacade,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService
  ) {
    this.openingHours.headline = translocoService.translate("location.openingHours.headline");
  }

  public ngOnInit(): void {
    this.locationsFacade.selectedServiceCenter$
      .pipe(filter((selectedServiceCenter: ServiceCenter) => !!selectedServiceCenter))
      .subscribe((selectedServiceCenter: ServiceCenter) => {
        this.content = selectedServiceCenter;
        // TODO: close mechanism and changedetection onPush / prepair async call
        this.isScClosed = this.content.openingHours.every((openingHour: OpeningHours) => !openingHour.start);
        this.buildTableFromRawData(this.content.openingHours);

        if (this.isScClosed) {
          delete this.appointmentCta.link;
        }

        this.buildServiceListFromRawData(this.content.mobilityOptions, this.content.customerComfortOptions);
        this.buildNotesFromRawData(this.content.notes);
        this.buildServicesFromRawData(this.content.characteristics);

        this.setRouteCtaHref();

        this.cdr.markForCheck();
      });

    this.breakpointService.isSmallMobile$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSmallMobile: boolean) => {
        this.isSmallMobile = isSmallMobile;
        this.cdr.markForCheck();
      });
  }

  public buildTableFromRawData(data: OpeningHours[]): void {
    if (this.isScClosed) {
      this.buildOpeningHoursForClosedServiceCenter(data);

      return;
    }

    this.buildOpeningHoursForOpenServiceCenter(data);
  }

  public buildNotesFromRawData(notes: string): void {
    this.notes = {
      text: notes,
      ngTemplate: "cgParagraph"
    };
  }

  public buildServiceListFromRawData(mobilityOptions: string[], customerComfortOptions: string[]): void {
    for (const item of mobilityOptions) {
      this.serviceList.items.push(item);
    }

    for (const item of customerComfortOptions) {
      this.serviceList.items.push(item);
    }
  }

  public buildServicesFromRawData(data: Characteristics): void {
    for (const currentService of SERVICE_ORDER) {
      if (data.services[currentService]) {
        this.services.push({
          term: currentService,
          description: data.services[currentService].replace("®", "<sup>®</sup>")
        });
      }
    }
  }

  public setRouteCtaHref(): void {
    this.routeCta.link.href = this.buildGoogleMapsRouteHref(this.content.address);
  }

  public buildGoogleMapsRouteHref(data: ServiceCenterAddress): string {
    return (
      "https://www.google.de/maps/dir//" +
      encodeURIComponent(`${data.street} ${data.streetNumber}, ${data.postalCode} ${data.city}`)
    );
  }

  public onClose(): void {
    this.locationsFacade.closeServiceCenterDetails();
    this.locationsFacade.clearSelectedServiceCenter();
  }

  public disableAppointmentCta(): number {
    return this.isScClosed ? 1 : -1;
  }

  public async goToOlb(event: MouseEvent, content: ServiceCenter): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    this.sharedFacade.setCurrentServiceCenter({
      location: await lastValueFrom(this.locationsFacade.search$.pipe(first())),
      costCenter: content.costCenter
    });
  }

  private buildOpeningHoursForClosedServiceCenter(data: OpeningHours[]): void {
    for (const item of data) {
      this.openingHours.rows.push([item.day, this.translocoService.translate("location.openingHours.closed"), ""]);
    }
  }

  private buildOpeningHoursForOpenServiceCenter(data: OpeningHours[]): void {
    for (const item of data) {
      const openingDay = item?.day?.substring(0, 2) || "";

      if (item.start) {
        if (item.breakStart) {
          this.openingHours.rows.push([
            openingDay,
            item.start + " - " + item.breakStart,
            item.breakEnd + " - " + item.end
          ]);
        } else {
          this.openingHours.rows.push([openingDay, item.start + " - " + item.end, ""]);
        }
      } else {
        this.openingHours.rows.push([openingDay, this.translocoService.translate("location.openingHours.closed"), ""]);
      }
    }
  }
}
